<template>
  <el-card
    :style="cardStyle"
    class="organization-card"
    :body-style="{ padding: '0px' }"
  >
    <div
      v-if="o[`OPMaterial`] != null && o[`OPMaterial`][`project_img`] != null"
      class="project-img-block"
      :style="
        `background-image: url('` +
          o[`OPMaterial`][`project_img`].replaceAll('\\', '/') +
          `') !important;`
      "
    ></div>
    <div
      v-else
      class="project-img-block project-img-block-default"
      style="background-image: url('/img/user_icon.f5012049.png') !important;"
    ></div>

    <div>
      <div class="project-bottom">
        <!-- Статус проекта-->
        <div class="project-status">
          <p>
            Статус: <span :style="statusStyle">{{ o["PStatus"]["name"] }}</span>
          </p>
          <template v-if="o[`PStatus`][`id`] == 7">
            <el-collapse>
              <el-collapse-item title="Комментарий проверяющего">
                {{ o["moderation_comment"] }}
              </el-collapse-item>
            </el-collapse>
          </template>
        </div>

        <!-- Название проекта-->
        <span class="project-name-header">{{ textCutter }}</span>

        <!-- Кол-во людей, что принимают участие в проекте -->
        <div class="project-members">
          <p>
            Кол-во людей, что подписалось на проект:
            {{ o["OFollowers"].length }}
          </p>
        </div>

        <!-- Описание проекта -->
        <el-collapse>
          <el-collapse-item title="Краткое описание">
            {{ o["project_short_desc"] }}
          </el-collapse-item>
        </el-collapse>

        <!-- Направления -->
        <div class="project-direction">
          <p>Направления:</p>
          <div v-if="o[`Directions`].length != 0">
            <span
              class="badge badge-default"
              :style="`background-color: ${tag.color} !important;`"
              v-for="tag in o[`Directions`]"
              :key="tag.id"
              >{{ tag.name }}</span
            >
          </div>
          <div v-else>
            <span>пусто</span>
          </div>
        </div>

        <!-- Кнопки карточки -->
        <div class="project-buttons">
          <template v-if="adminEditMode">
            <el-button @click="runEdit(o.id)" type="info"
              >Проверить проект</el-button
            >
          </template>
          <template v-else>
            <el-button @click="runDelete(o.id)" type="danger"
              >Удалить</el-button
            >
            <el-button @click="runEdit(o.id)" type="info"
              >Редактировать</el-button
            >
          </template>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    o: Object,
    runEdit: { type: Function },
    runDelete: { type: Function },
    adminEditMode: Boolean,
  },
  data() {
    return {
      statusStyle: "",
      cardStyle: "",
    };
  },
  async created() {
    switch (this.o["PStatus"]["id"]) {
      case "7": {
      }
      case 7: {
        this.statusStyle = "color: red; ";
        this.cardStyle = "box-shadow: 0px 2px 12px 0 rgba(255, 118, 118, 0.7)";
        break;
      }
      case "8": {
      }
      case 8: {
        this.statusStyle = "color: green; font-weight: 600;";
        this.cardStyle = "box-shadow: 0px 2px 12px 0 #9bff76b3";
        break;
      }
    }
  },
  computed: {
    textCutter() {
      if (this.o["project_name"].length > 37) {
        return `${this.o["project_name"].substr(0, 37)}...`;
      } else {
        return this.o["project_name"];
      }
    },
  },
};
</script>

<style>
.el-col {
  width: 400px;
  max-width: 400px !important;
  margin: 4vh 2vw;
}

.organization-card .el-collapse-item__content {
  text-align: left;
  padding: 2% 5%;
  word-break: break-word;
  font-size: 0.75vw !important;
}

.organization-card .el-collapse-item__header {
  padding-left: 5%;
  font-size: 0.85vw !important;
  word-break: break-word;
}
</style>

<style scoped>
.project-direction {
  position: relative;

  height: 160px;

  margin-top: 2%;
  padding: 2%;
}
.project-direction > p {
  text-align: center;
}
.project-direction > div > span {
  margin: 1%;
}

.project-name-header {
  position: relative;

  display: block;

  width: 100%;
  height: 60px;

  padding: 0% 5%;

  word-break: break-word;
  font-size: 1vw;
  font-weight: 600;
}

.project-status {
  position: relative;
  padding: 0% 5%;
  margin: 50px 0%;
}

.project-members {
  margin: 20px 0%;
  padding: 0% 20px;
}
.project-buttons {
  padding: 17px 0%;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.org-profile h4 {
  margin-bottom: 4vh;
}

.project-img-block {
  width: 400px;
  height: 300px;

  background-position: 50% 50% !important;
  background-repeat: no-repeat;
  background-size: 400px auto !important;
}
.project-img-block-default {
  background-size: 180px auto !important;
}
</style>
