import request from '@/api/config.js'

export function updateDirection(id, data) {
    return request({
        url: `direction/${id}`,
        method: 'put',
        data
    })
}

export function findByIdDirection(id) {
    return request({
        url: `direction/${id}`,
        method: 'get'
    })
}

export function getAllDirection() {
    return request({
        url: `direction`,
        method: 'get'
    })
}

export function createDirection(data) {
    return request({
        url: `direction/create`,
        method: 'post',
        data
    })
}

export function deleteDirection(id) {
    return request({
        url: `direction/${id}`,
        method: 'delete'
    })
}