import request from '@/api/config.js'

export function createOrganization(data) {
    return request({
        url: `organization/create`,
        method: 'post',
        data
    })
}

export function updateOrganization(id, data) {
    return request({
        url: `organization/update/${id}`,
        method: 'post',
        data
    })
}

export function getAllOrganizations() {
    return request({
        url: `organization/getAllOrganizations`,
        method: 'get'
    })
}