import request from '@/api/config.js'

export function createProject(data) {
    return request({
        url: `organization_projects/createProject`,
        method: 'post',
        data
    })
}

export function updateProject(data) {
    return request({
        url: `organization_projects/updateProject`,
        method: 'post',
        data
    })
}

export function deleteProject(data) {
    return request({
        url: `organization_projects/deleteProject`,
        method: 'post',
        data
    })
}

export function addFollowerToProject(data) {
    return request({
        url: `organization_projects/addFollowerToProject`,
        method: 'post',
        data
    })
}



export function getAllProjects() {
    return request({
        url: `organization_projects/getAllProjects`,
        method: 'get'
    })
}

export function getAllApprovedProjects() {
    return request({
        url: `organization_projects/getAllApprovedProjects`,
        method: 'get'
    })
}



export function getProjectsList(data) {
    return request({
        url: `organization_projects/getProjectsList`,
        method: 'post',
        data
    })
}

export function getProject(data) {
    return request({
        url: `organization_projects/edit`,
        method: 'post',
        data
    })
}