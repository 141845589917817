<template>
  <div class="chart">
    <highcharts ref="chartComponent" :options="chartOptions" :update-args="updateArgs" />
  </div>
</template>

<script>
// import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
export default {
  components: {
    highcharts: Chart
  },
  props: {
    series: {
      type: Array,
      default: function() {
        return []
      }
    },
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
      selectCell: null
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: '65%',
          backgroundColor: 'rgba(0,0,0,0)'
        },
        legend: {
          verticalAlign: 'top'
        },
        title: {
          text: this.text
          // align: 'center',
          // verticalAlign: 'middle'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        colors: ['#ffffff'],
        plotOptions: {
          pie: {
            // borderColor: '#1A4780',
            borderColor: '#ffffff',
            allowPointSelect: true,
            dataLabels: {
              enabled: false
            },
            slicedOffset: 0,
            states: {
              hover: {
                enabled: false
              },
              inactive: {
                opacity: 1
              }
            },
            point: {
              events: {
                click: (event) => { this.selectCallback(event) },
                legendItemClick: e => {
                  return false
                }
                // unselect: (event) => { this.unselectCallback(event) }
              }
            }
          }
        },
        series: this.series
      }
    }
  },
  methods: {
    selectCallback(event) {
      this.selectCell = event.point
      var selectLevel = this.series.filter(item => item.custom.level_id === this.selectCell.custom.level_id)[0]
      var answer = {
        section_id: this.selectCell.custom.section_id,
        levels: []
      }
      this.series.forEach(element => {
        if (element.custom.section_id === this.selectCell.custom.section_id && parseFloat(element.size) >= parseFloat(selectLevel.size)) {
          answer.levels.push(element.custom.level_id)
        }
      })
      this.$emit('select-cell', answer)
      this.$refs.chartComponent.chart.redraw()
      // updateCell({ user_id: this.user, level_id: this.selectCell.custom.level_id }).then(() => {
      //   event.point.color = event.point.color.slice(-2)[0] === '3' ? event.point.color.slice(0, -2) + '8)' : event.point.color.slice(0, -2) + '3)'
      //   this.$refs.chartComponent.chart.redraw()
      // })
    }
  }
}
</script>
<style scoped>
.width-100 {
  width: 100px;
}
.margin-10 {
  margin-bottom: 10px;
}
</style>
