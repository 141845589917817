import request from '@/api/config.js'

export function createExtern(data) {
    return request({
        url: `extern/create`,
        method: 'post',
        data
    })
}

export function getAllNotCheckedExtern() {
    return request({
        url: `extern/get/not-checked`,
        method: 'get'
    })
}

export function updateExtern(id, data) {
    return request({
        url: `extern/${id}`,
        method: 'put',
        data
    })
}

