<template>
  <div class="directionCard">
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          {{ direction.name }}
        </div>
      </template>
      <div class="card-body">
        <div
          class="section-item"
          v-for="section in direction.sections"
          :key="section.id"
        >
          <router-link :to="'/edu/about/' + section.id">
            <span class="sectionName" lang="ru">
              {{ section.name }}
            </span>
            <div class="right-side">
              <div class="progress">
                <el-progress
                  type="line"
                  :text-inside="true"
                  :stroke-width="17"
                  :percentage="section.progress"
                  color="#21b85d"
                >
                  <div v-bind:class="{ progressText: section.progress <= 10 }">
                    {{ section.progress }} %
                  </div>
                </el-progress>
              </div>
              <div>
                <i class="fa fa-arrow-right"></i>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "DirectionCard",
  data() {
    return {};
  },
  props: {
    direction: {
      type: Object,
      default: function() {
        return {};
      },
    },
    user: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  created() {
    let userLevels = [];
    let intersection = [];
    this.user.user_cells.forEach((element) => {
      userLevels.push(element["level_id"]);
    });
    this.direction.sections.forEach((section) => {
      let sectionLevels = [];
      section.degrees.forEach((degree) => {
        degree.levels.forEach((level) => {
          sectionLevels.push(level.id);
        });
      });
      intersection = sectionLevels.filter((x) => userLevels.includes(x));
      section.progress = Math.round(
        (intersection.length / sectionLevels.length) * 100,
        0
      );
    });
  },
};
</script>

<style scoped>
.right-side {
  display: flex;
  width: 30%;
  justify-content: flex-end;
}
.progress {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 17px;
  margin-right: 15px;
}
.progressText {
  color: black !important;
}
.el-card {
  margin: auto;
  margin-bottom: 20px;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 10px !important;
  box-shadow: 2px 4px 4px 2px rgba(163, 163, 163, 0.2);
}
.card-header {
  border: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600 !important;
  color: black !important;
  font-size: 1.2em !important;
  padding: 0px !important;
}

.card-body {
  padding: 0px !important;
  font-size: 0.9em;
  font-weight: 600;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  font-family: "Montserrat" !important;
}
.section-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}
i {
  color: #2c3e50;
}
.section-item a {
  width: 100%;
  display: flex;
  color: #2c3e50;
  justify-content: space-between;
  gap: 20px;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .card-body > .section-item .sectionName {
    font-size: 0.9rem !important;
    position: relative;
    display: block;
    height: auto;
    line-height: 1rem !important;
    text-align: left;
    width: fit-content;
    hyphens: auto;
  }

  .card-body {
    gap: 12px;
  }

  .right-side {
    flex-shrink: 0;
  }

  .progress {
    margin-right: 0;
    height: fit-content !important;

    margin-bottom: auto;
    margin-top: auto;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .card-body > .section-item .sectionName {
    font-size: 0.9rem !important;
    position: relative;
    display: block;
    height: auto;
    line-height: 1rem !important;
    text-align: left;
    width: fit-content;
    hyphens: auto;
  }

  .card-body {
    gap: 12px;
  }

  .right-side {
    flex-shrink: 0;
  }

  .progress {
    margin-right: 0;
    height: fit-content !important;

    margin-bottom: auto;
    margin-top: auto;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .card-body > .section-item .sectionName {
    font-size: 1rem !important;
  }
}
@media (min-width: 992px) {
  .card-body > .section-item .sectionName {
    font-size: 1rem !important;
  }
}
</style>
