import request from '@/api/config.js'

export function updateTask(id, data) {
    return request({
        url: `task/${id}`,
        method: 'put',
        data
    })
}

export function findByIdTask(id) {
    return request({
        url: `task/${id}`,
        method: 'get'
    })
}

export function getAllTask() {
    return request({
        url: `task`,
        method: 'get'
    })
}

export function getAllTaskByLevel(level_id) {
    return request({
        url: `task/level/${level_id}`,
        method: 'get'
    })
}

export function createTask(data) {
    return request({
        url: `task/create`,
        method: 'post',
        data
    })
}

export function deleteTask(id) {
    return request({
        url: `task/${id}`,
        method: 'delete'
    })
}

export function createMaterial(data) {
    return request({
        url: `material/create`,
        method: 'post',
        data
    })
}

export function deleteMaterial(id) {
    return request({
        url: `material/${id}`,
        method: 'delete'
    })
}
