<template>
  <div class="user-task-history">
    <h4 class="header">История попыток</h4>
    <div class="history-items-headers">
      <template v-if="!isTestHistory">
        <div>Дата попытки</div>
        <div v-if="!this.task_name">Задача</div>
        <div>Статус</div>
        <div>Комментарий студента</div>
        <div>Комментарий наставника</div>
      </template>
      <template v-else>
        <div>Дата попытки</div>
        <div>Статус</div>
        <div>% выполнения</div>
      </template>
    </div>
    <template v-if="!isTestHistory">
      <div class="history-item" v-for="attempt in this.history" :key="attempt">
        <div>{{ getFormattedDate(attempt.createdAt) }}</div>
        <div v-if="!this.task_name">{{ attempt.task.name }}</div>
        <div>{{ attempt.status.name }}</div>
        <div class="user-comment custom-scrollbar ql-snow">
          <span class="ql-editor" v-html="attempt.description"></span>
        </div>
        <div class="user-comment custom-scrollbar ql-snow">
          <span class="ql-editor" v-html="attempt.comment"></span>
        </div>
      </div>
      <div v-if="this.history.length == 0" class="empty-history">
        Вы пока не совершили ни одной попытки
      </div>
    </template>
    <template v-else>
      <div
        class="history-item"
        v-for="attempt in getReversedArray"
        :key="attempt"
      >
        <div>{{ getFormattedDate(attempt.createdAt) }}</div>
        <div style="text-align: left;">
          {{
            attempt.fact_finish == null
              ? "В процессе прохождения"
              : attempt.score >= TestSuccessPercent
              ? "Сдан"
              : "Не сдан"
          }}
        </div>
        <div style="text-align: left;" class="user-comment custom-scrollbar">
          {{ attempt.score }} %
        </div>
        <div class="user-comment custom-scrollbar">{{ attempt.comment }}</div>
      </div>
      <div v-if="TestHistory.length == 0" class="empty-history">
        Вы пока не совершили ни одной попытки
      </div>
    </template>
  </div>
</template>

<script>
import { findByUserAndLevel } from "@/api/solution.js";
export default {
  name: "UserTaskHistory",
  props: {
    user: {
      type: String,
    },
    level: {
      type: Number,
    },
    taskName: {
      type: Boolean,
      default: true,
    },

    isTestHistory: {
      type: Boolean,
      default: false,
    },
    TestHistory: {
      type: Array,
      default: [],
    },
    TestSuccessPercent: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      history: [],
    };
  },
  created() {
    findByUserAndLevel({ user: this.user, level: this.level }).then(
      (response) => {
        console.log(response);
        this.history = response.data;
      }
    );
  },
  methods: {
    getFormattedDate(date) {
      let d = new Date(date);
      return (
        d.getDate() +
        "." +
        (d.getMonth() + 1) +
        "." +
        d.getFullYear() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    },
  },
  computed: {
    getReversedArray() {
      return this.TestHistory.reverse();
    },
  },
};
</script>

<style>
.user-task-history {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.header {
  font-weight: 600;
}

.history-items-headers {
  font-size: 0.9em;
  padding-bottom: 15px;
  border-bottom: 1px solid #e1e6ea;
  width: 100%;
  display: grid;
  grid-template-columns: 10% 15% 15% 30% 30%;
  justify-items: flex-start;
  column-gap: 1%;
  margin: 30px 0px;
  font-weight: 600;
  text-align: start;
}

.history-item {
  font-size: 0.9em;
  width: 100%;
  display: grid;
  grid-template-columns: 10% 15% 15% 30% 27%;
  justify-items: flex-start;
  column-gap: 1%;
  text-align: justify;
}

.history-item > .user-comment {
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  word-break: break-word;
}

.history-item div {
  color: #fff;
  height: 120px;
  overflow-y: auto;
  padding-right: 10px;
}

.history-item:last-child {
  margin-bottom: 100px;
}
.history-item div::-webkit-scrollbar {
  width: 12px;
}

.history-item div::-webkit-scrollbar-track {
  background: rgba(243, 239, 239, 0.463);
  border-radius: 20px;
}

.history-item div::-webkit-scrollbar-thumb {
  background-color: rgba(150, 135, 135, 0.433);
  border-radius: 20px;
}

.empty-history {
  display: flex;
  margin-bottom: 100px;
}
</style>

<style scoped>
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

@media (max-width: 480px) {
  .user-task-history {
    overflow-x: auto;
  }

  .user-task-history > .history-items-headers {
    grid-template-columns: 30% 35% 25% 40% 40%;
    column-gap: 10px;
    border-bottom: none !important;
  }
  .user-task-history > .history-items-headers > div {
    font-size: 0.65rem;
  }

  .user-task-history > .history-item {
    grid-template-columns: 30% 35% 25% 40% 40%;
    column-gap: 10px;
  }
  .user-task-history > .history-item > div {
    font-size: 0.55rem;
    padding: 0%;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .user-task-history > .history-items-headers {
    grid-template-columns: 10% 15% 15% 30% 30%;
    column-gap: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .user-task-history > .history-items-headers > div {
    font-size: 0.7rem;
  }

  .user-task-history > .history-item {
    grid-template-columns: 10% 15% 15% 30% 27%;
    column-gap: 10px;
  }
  .user-task-history > .history-item > div {
    font-size: 0.65rem;
    padding: 0%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .user-task-history > .history-items-headers {
    grid-template-columns: 10% 15% 15% 30% 30%;
    column-gap: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .user-task-history > .history-items-headers > div {
    font-size: 0.9rem;
  }

  .user-task-history > .history-item {
    grid-template-columns: 10% 15% 15% 30% 27%;
    column-gap: 10px;
  }
  .user-task-history > .history-item > div {
    font-size: 0.8rem;
    padding: 0%;
  }
}
</style>
