<template>
  <div>
    <el-card
      v-for="(level, i) in levels"
      :key="`${i}${level.id}`"
      class="box-card"
    >
      <template #header>
        <div class="card-header">
          <div>
            <span>{{ "Уровень " + level.level + ". " + level.name }}</span>
            <!-- <template
              v-if="
                user &&
                user.user_cells.length > 0 &&
                user.user_cells.find((el) => el.level_id === level.id) !==
                  undefined
              "
            >
              <i class="el-icon-check head-i"></i>
            </template>
            <template
              v-else-if="
                i === 0 ||
                (user &&
                  user.user_cells.length > 0 &&
                  user.user_cells.find(
                    (el) => el.level_id === levels[i - 1].id
                  ) !== undefined)
              "
            >
              <i class="el-icon-unlock head-i"></i>
            </template>
            <template v-else>
              <i class="el-icon-lock head-i-lock"></i>
            </template> -->
          </div>
          <!-- <span>Дедлайн: 01.01.2022</span> -->
          <!-- <el-tooltip
            content="Примерное время выполнения: 20 мин"
            placement="right"
            effect="dark"
          > -->
          <!-- <el-tooltip content="Подсказка" placement="right" effect="dark">
            <i class="el-icon-info"></i>
          </el-tooltip> -->
        </div>
      </template>
      <div class="text item">
        <div class="desc-item">
          {{ level.description }}
          <!-- <p>Получаемые компетенции:</p> -->
        </div>
        <!-- <div class="btn-item">
          <el-button v-if="i === 4" plain>Получить экстерном</el-button>
        </div> -->
        <div class="btn-item">
          <!-- <template
            v-if="
              i === 0 ||
              (user &&
                user.user_cells.length > 0 &&
                user.user_cells.find(
                  (el) => el.level_id === levels[i - 1].id
                ) !== undefined)
            "
          > -->
          <template
            v-if="
              user &&
                user.user_cells.length > 0 &&
                user.user_cells.find((el) => el.level_id === level.id) !==
                  undefined
            "
          >
            <ButtonGradientOutsideElement
              class="BGOEButton"
              @click="
                this.$router.push({
                  name: 'LevelPage',
                  params: { level: level.id },
                })
              "
              ButtonText="Уровень получен"
            />
          </template>
          <template v-else>
            <ButtonGradientInsideElement
              class="BGIEButton"
              @click="
                this.$router.push({
                  name: 'LevelPage',
                  params: { level: level.id },
                })
              "
              ButtonText="Получить уровень"
            />
          </template>
          <!-- </template> -->
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import ButtonGradientInsideElement from "@/components/CustomElements/ButtonGradient";
import ButtonGradientOutsideElement from "@/components/CustomElements/ButtonGradientBorder";

import { mapGetters } from "vuex";
export default {
  components: { ButtonGradientInsideElement, ButtonGradientOutsideElement },
  props: {
    levels: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
};
</script>

<style scoped>
.BGIEButton,
.BGOEButton {
  min-width: 185px !important;
  width: auto !important;
  height: auto !important;
  padding: 12px 24px !important;
  flex-shrink: 0;
}
.BGIEButton:deep(.Text),
.BGOEButton:deep(.Text) {
  font-weight: 400 !important;
  font-size: 0.95rem !important;
  line-height: 155% !important;
}

.lvl_done {
  background-color: #21b85d !important;
}
.lvl_done:hover {
  background-color: #21b85d !important;
}

.item p {
  margin-top: 10px;
  font-weight: 600;
  color: black !important;
}
.head-i {
  margin-left: 10px;
  font-weight: 600;
  color: #21b85d !important;
}

.head-i-lock {
  margin-left: 10px;
  font-weight: 600;
  color: #d64a4a !important;
}

.head {
  margin-bottom: 50px;
  display: flex !important;
  align-items: center;
}

.el-card {
  margin: auto;
  margin-bottom: 20px;
  font-family: "Montserrat" !important;
  color: #2c3e50 !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 10px !important;
}
i:hover {
  color: #d64a4a !important;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none !important;
  font-weight: 600 !important;
  color: black !important;
  /* color: #2c3e50 !important; */
  font-size: 1.2em !important;
  padding: 0px !important;
}
.text {
  font-size: 14px;
}
.item {
  display: flex !important;
  align-items: center !important;
  /* margin-bottom: 18px; */
}
.btn-item {
  display: flex;
  justify-content: flex-end;
  width: 20%;
}
.el-button {
  background-color: #d64a4a;
  color: #fff !important;
  padding: 0.2rem 1rem;
  font-size: 0.8rem;
  border-radius: 10px !important;
  border: none !important;
}
.el-button:hover {
  background-color: #d64a4a;
  opacity: 0.9 !important;
  color: #fff !important;
  padding: 0.5rem 1rem !important;
  border: none !important;
}

.desc-item {
  width: 80%;
  text-align: start;
}
.box-card {
  width: 100%;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .text.item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .text.item > .desc-item {
    width: 100%;
    font-size: 1rem !important;
    color: #dbdbdb;
  }

  .text.item > .btn-item {
    width: auto !important;
    height: auto !important;
    margin: 20px auto 0px auto;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .text.item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .text.item > .desc-item {
    width: 100%;
    font-size: 1rem !important;
    color: #dbdbdb;
  }

  .text.item > .btn-item {
    width: auto !important;
    height: auto !important;
    margin: 20px auto 0px auto;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .text.item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .text.item > .desc-item {
    width: 100%;
    font-size: 1rem !important;
    color: #dbdbdb;
  }
  .text.item > .btn-item {
    width: auto !important;
    height: auto !important;
    margin: 20px auto 0px auto;
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  .text.item {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
  }
  .text.item > .desc-item {
    width: 100%;
    font-size: 1rem !important;
    color: #dbdbdb;
  }
  .text.item > .btn-item {
    width: auto !important;
    height: auto !important;
  }
}
@media (min-width: 1400px) {
  .text.item {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
  }
  .text.item > .desc-item {
    width: 100%;
    font-size: 1rem !important;
    color: #dbdbdb;
  }
  .text.item > .btn-item {
    width: auto !important;
    height: auto !important;
  }
}
</style>
